html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito';
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
}

:root {
    --logo-sz: 150px;
    --samaaro-blue: #435ebe;
    --samaaro-bg-blue: #f2f7ff;
    --informa-green: #33d27e;
    /* --informa-green-dark: #155332; */
    margin: 0;
    padding: 0;
}

/* scroll bar styles */

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: var(--informa-green);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.reg-card-landing a {
    color: var(--informa-green) !important;
}

.side-by-side {
    display: flex;
}

.side-by-side > div {
    display: flex;
    align-items: start;
    /* justify-content: center; */
    flex-grow: 1;
    text-align: center;
}

.contact-page .side-by-side > div {
    align-items: center;
}

.side-by-side input,
.side-by-side select {
    background-color: white;
}

.contact-page .side-by-side label {
    flex-grow: 1;
}

.side-by-side label {
    /* margin-right: 24px; */
    text-align: center;
    font-size: 1.2rem;
}

.side-by-side .display-data {
    font-size: 1.2rem;
    text-align: left;
    word-break: break-all;
}

.note-text {
    margin-top: 3px;
    font-size: 0.9rem;
    text-align: left;
    color: rgb(177, 177, 177);
}

.fs-11 label {
    font-size: 1.1rem !important;
}

.ck.ck-editor {
    width: 100% !important;
}

.border-custom {
    height: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    overflow: hidden;
}

.border-dashed {
    border: 3px dashed var(--informa-green);
}

.grey-box {
    background-color: #f4f6f8;
    border-color: transparent;
    padding: 1rem;
    width: 100%;
    min-height: 5rem;
}

.reg-card-landing {
    min-width: 90vw;
    max-width: 90vw;
    max-height: 86vh;
    height: 86vh;
    /* background-color: white; */
    /* border-radius: 15px; */
    /* overflow: hidden; */
}

.MuiOutlinedInput-root.Mui-focused {
    border-color: #2065d1 !important;
}

.blue-login {
    color: var(--samaaro-blue) !important;
}

.green-color {
    color: var(--informa-green) !important;
}

.black-login {
    color: black !important;
}

.bg-blue-login {
    background-color: var(--samaaro-blue) !important;
}

.main-landing-page .carousel-indicators {
    bottom: -50px;
}

.main-landing-page .carousel-indicators button {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin: auto 5px;
    transition: 0.4s linear all;
}

.main-landing-page .carousel-indicators [data-bs-target] {
    background-color: #131313;
}

.main-landing-page .carousel-indicators .active {
    height: 11px;
    width: 11px;
}

.fit-cover {
    object-fit: cover !important;
}

.fit-contain {
    object-fit: contain !important;
}

.grid-landing {
    max-height: 72vh;
    overflow-y: auto;
}

.grid-landing::-webkit-scrollbar {
    width: 3px;
}

.grid-landing::-webkit-scrollbar-thumb {
    background-color: #131313;
    border-radius: 8px;
    outline: 1px solid slategrey;
}

.form-scroll {
    max-height: max(73vh, 360px);
    overflow-y: auto;
}

.form-scroll::-webkit-scrollbar {
    width: 3px;
}

.form-scroll::-webkit-scrollbar-thumb {
    background-color: #131313;
    border-radius: 8px;
    outline: 1px solid slategrey;
}

.green-btn {
    border: 1px solid var(--informa-green);
    background-color: var(--informa-green);
    color: white;
}

.green-border-btn {
    border: 1px solid var(--informa-green);
    color: black;
}

.green-btn:hover {
    background-color: var(--informa-green);
}

.border-radius-100 {
    border-radius: 100% !important;
}

.border-radius-50 {
    border-radius: 50% !important;
}

.border-radius-15px {
    border-radius: 15px !important;
}

.box-shadow-1 {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Crop-Controls {
    margin: 20px auto 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.img-validation {
    display: flex;
    font-size: 1rem;
    justify-content: center;
    background-color: rgba(255, 207, 207, 0.651);
    margin: 20px 0 10px;
    border-radius: 10px;
    padding: 10px 30px;
}

.expand_tabs button {
    flex-grow: 1;
    border: 1px solid var(--informa-green);
    color: rgb(27, 27, 27);
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.tabs_icon button {
    border: 1px solid #2c2c51;
    color: #2c2c51;
    border-radius: 8px;
}

.select_box button {
    border: 0px;
    background-color: #c2c9d8;
    color: black;
    border-radius: 6px;
    padding: 2px 12px;
    margin-bottom: 0.7rem;
    white-space: nowrap;
}

.select_box button.active {
    border: 0px;
    color: white;
    background-color: #a04ff1;
}

.showcase__title {
    margin-left: calc(var(--logo-sz) + 60px);
    text-align: left;
    /* margin-top: calc(var(--logo-sz) / 2 - 10px); */
}

.hashtag-box {
    cursor: pointer;
    white-space: nowrap;
    background-color: #a04ff1;
    color: white;
    padding: 1px 15px;
    margin: 10px 10px 6px 0px;
    font-size: 18px;
    /* margin-right: 5px; */
    border-radius: 6px;
}

.hashtag-box-informa {
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid black;
    color: black;
    padding: 1px 15px;
    margin: 10px 10px 6px 0px;
    font-size: 16px;
    /* margin-right: 5px; */
    border-radius: 6px;
}

.zIndex-10000 {
    z-index: 10000 !important;
}

.big-modal {
    min-width: 80vw;
    max-width: 90vw;
    max-height: 80vh;
}

.medium-modal {
    min-width: 65vw;
    max-width: 75vw;
    max-height: 80vh;
}

.extra-height {
    max-height: 90vh;
}

.h-69vh {
    height: 69vh;
}

.hashtags-title {
    background-color: #ddd;
    padding: 5px;
    border-radius: 5px;
    margin-right: 4px;
    font-size: 0.85rem;
    color: black;
    white-space: nowrap;
}

.ck-toolbar__items {
    flex-wrap: wrap !important;
}

.custom-select {
    width: 50%;
    height: 1.43em;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

.table-img {
    margin: auto;
    max-width: 100px;
    max-width: 100px;
    padding: 0.2rem;
    border: 1px solid black;
    border-radius: 10px;
    transition: 0.3s ease-in all;
    background-color: white;
}

.table-img img {
    border-radius: 10px;
}

.table-img:hover {
    transform: scale(1.5);
}

.ck.ck-icon.ck-reset_all-excluded.ck-icon_inherit-color.ck-dropdown__arrow {
    z-index: auto;
}

.banner__img {
    max-width: 100%;
    max-height: 400px;
    display: flex;
    justify-content: center;
    background-color: black;
    /* transition: 1s ease-in all; */
    border-radius: 30px 30px 30px 0;
    overflow: hidden;
}

.banner__img2 {
    border-radius: 0;
}

.banner__img img {
    /* border-radius: 30px 30px 30px 0; */
    object-fit: cover;
    transition: 0.2s ease-in all;
    width: 100%;
}

/* .banner__img img:hover {
    width: 85%;
    max-width: 100%;
} */

.banner__img iframe {
    width: 100%;
    max-width: 100%;
    height: 400px;
}

.logo__img {
    height: var(--logo-sz);
    width: var(--logo-sz);
    bottom: calc((-1 * var(--logo-sz)) / 2);
    margin-left: 24px;
    left: 0;
    z-index: 10;
    position: absolute;
    border-radius: 50%;
    border: 0.4rem solid white;
    background-color: white;
    overflow: hidden;
}

.logo__img img {
    object-fit: contain;
}

.img__block {
    position: relative;
    /* padding-bottom: calc(var(--logo-sz) / 2); */
}

.my-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.p-1-5 {
    padding: 1.5rem;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.pointer-event-none {
    pointer-events: none !important;
}

.doc__box {
    height: auto;
    min-height: 80px;
    background-color: white;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    object-fit: cover;
    overflow: auto;
}

.icon__box {
    display: flex;
    padding: 0;
    font-size: 50px;
    /* flex-grow: 1; */
    align-items: center;
}

.scale-like {
    transition: 0.1s ease all;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}

.scale-like:hover {
    transform: scale(1.1);
}

.title__box {
    flex-grow: 1;
    font-size: 1.04rem;
    /* background-color: #c4cbff; */
    width: 100%;
    height: 100%;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: start;
}

.img_vid__box {
    height: 160px;
    width: auto;
    object-fit: contain;
    overflow: hidden;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.img_vid__box2 {
    width: auto;
    object-fit: contain;
    overflow: hidden;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.img_vid__box2 .image,
.img_vid__box2 .video {
    transition: 0.2s ease all;
}

.img_vid__box2 .image:hover,
.img_vid__box2 .video:hover {
    transform: scale(1.15);
}

.playBtn {
    position: absolute;
    width: 60px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal_view__img {
    object-fit: cover;
    max-width: 39vw;
    height: 60vh;
    /* max-height: 45vh, */
    width: auto;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.product__img {
    height: 350px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
}

.showcase_max_ht .custom-shadow {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
        rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.custom-shadow2 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.showcase_max_ht img {
    max-height: 279px;
    width: 100%;
    object-fit: contain;
}

.showcase_max_ht .title__box {
    background-color: #2c2c51 !important;
    color: white;
}

.product_max_ht img {
    max-height: 275px;
    object-fit: contain;
}

.product__img img {
    height: 100%;
    max-height: 380px;
}

.display-name-value {
    margin-bottom: 1rem;
}

.display-name-value > div:nth-child(1) {
    color: rgb(34, 34, 77);
    font-size: 0.9rem;
    font-weight: 600;
}

.display-name-value > div:nth-child(2) {
    color: black;
    font-size: 1.1rem;
}

.circle-icon {
    border: 1px solid #d1d1d1;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    margin: 1.5rem 10px;
}

.circle-icon svg {
    filter: brightness(0);
}

.ellipse-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.container-with-dots {
    height: 110px;
}

/* .container-with-dots li:not(:last-child) {
  margin-right: 10px;
} */

/* ALL WIDTHS */
.w-80px {
    max-width: 80px;
}

.w-130px {
    max-width: 130px;
}

.w-150px {
    max-width: 150px;
}

.w-160px {
    max-width: 160px;
}

.w-170px {
    max-width: 170px;
}

.w-180px {
    max-width: 180px;
}

.w-190px {
    max-width: 190px;
}

.w-200px {
    max-width: 200px;
}

.w-250px {
    max-width: 250px;
}

.w-300px {
    max-width: 300px;
}

.w-315px {
    max-width: 315px;
}

.w-340px {
    max-width: 340px;
}

.contact-page {
    padding: 0 2rem;
}

.product-name-box {
    width: 40%;
    flex-shrink: 0;
}

th.table-header-clr {
    color: #637381;
    background-color: inherit;
}

.fixed-left {
    position: sticky;
    left: 0;
    /* background-color: white; */
    background-color: inherit;

    z-index: 1;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1 !important;
}

.social_media_icon_sz {
    height: 50px;
    width: 50px;
}

.social_allin1_icon_sz {
    height: 30px;
    width: 50px;
}

.simplebar-offset:focus-visible,
.simplebar-content-wrapper:focus-visible,
.simplebar-content:focus-visible {
    outline: 0;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
    margin: auto 0.4rem;
}

.font-main-title-custom {
    font-size: 30px;
    font-weight: 600;
}

.font-title-custom {
    font-size: 22px;
    font-weight: 600;
}

.font-body-custom {
    font-size: 16px;
}

.font-body-14 {
    font-size: 14px;
}

.font-body-12 {
    font-size: 12px;
}

.all_showcase_logo {
    --var-wt: 120px;
    height: var(--var-wt);
    width: var(--var-wt);
    border-radius: 100%;
    overflow: hidden;
}

.box-shadow-none {
    box-shadow: none;
}

.event_grid,
.event_grid_4 {
    display: grid;
    grid-gap: 1.2rem;
    grid-template-columns: repeat(3, minmax(240px, 1fr));
}

.event_grid_4 {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
}

.border_radius_100 {
    border-radius: 100%;
}

.border_radius_8px {
    border-radius: 8px;
}

.border_radius_1 {
    border-radius: 1rem;
}

.border_radius_1_2 {
    border-radius: 1.2rem;
}

.border_radius_2 {
    border-radius: 2rem;
}

.event_box {
    padding: 0.5rem;
    /* background-color: #d7dffdac; */
    /* border-radius: 5px; */
    /* overflow: hidden; */
}

.event_box img {
    object-fit: cover;
    aspect-ratio: 16/9;
    max-height: 240px;
    width: 100%;
}

.single-product {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.single-product ul {
    grid-gap: 10px;
}

.other-product ul {
    grid-gap: 20px;
}

/* HEART in Product page start */
[id='heart'] {
    position: absolute;
    left: -100vw;
}

[for='heart'] {
    color: #aab8c2;
    cursor: pointer;
    font-size: 2.2em;
    align-self: center;
    transition: color 0.2s ease-in-out;
}

[for='heart']:hover {
    color: grey;
}

[for='heart']::selection {
    color: none;
    background: transparent;
}

[for='heart']::moz-selection {
    color: none;
    background: transparent;
}

[id='heart']:checked + label {
    color: #e2264d;
    will-change: font-size;
    animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

@keyframes heart {
    0%,
    17.5% {
        font-size: 2.2em;
    }
}

/* HEART in Product page end */

.doc_icon_size {
    height: 50px;
    width: 42px;
}

/* SEE MORE START */
.see-more-content {
    height: 100px;
    overflow: hidden;
}

.container-see-more {
    position: relative;
    margin-bottom: 1.5rem;
}

.container-see-more label {
    position: absolute;
    top: 100%;
    right: 0;
    cursor: pointer;
}

.container-see-more input {
    display: none;
}

.container-see-more label:after {
    content: 'Show More';
    color: var(--informa-green);
}

.container-see-more input:checked + label:after {
    content: 'Show Less';
}

.container-see-more input:checked ~ div {
    height: 100%;
}

/* SEE MORE END */

/* ALL HEIGHTS */
.h-inherit {
    height: inherit !important;
}

.h-100px {
    height: 100px;
}

.h-130px {
    height: 130px;
}

.h-200px {
    height: 200px;
}

.h-250px {
    height: 250px;
}

.h-415px {
    height: 415px;
}

.h-420px {
    height: 420px;
}

.min-h-add-btn {
    min-height: 270px;
}

.min-h-auto {
    min-height: auto !important;
}

.squre-border-round {
    border-radius: 100%;
    border: 2px solid black;
}

/* ALL OPACITY */
.opacity-5 {
    opacity: 0.5 !important;
}

.round-border {
    border: 1.5px solid #001c39;
}

.user-guide-img-h {
    height: 164px;
}

.user-guide-content-h {
    max-height: 300px;
    overflow: auto;
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
    width: auto;
}

.modal-header-blue .modal-modal-title {
    color: var(--samaaro-blue);
}

.bg-blue {
    background-color: var(--samaaro-bg-blue) !important;
}

.box_click {
    position: relative;
}

.hidden_box {
    /* display: none; */
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 350ms ease all;
    color: white;
    background-color: rgba(67, 94, 190, 0.5);
    opacity: 0;
}

.box_click:hover .hidden_box {
    /* display: block; */
    opacity: 1;
}

.button-cp {
    float: left;
    min-width: 150px;
    /* max-width: 250px; */
    display: block;
    margin: 1em;
    padding: 1em 2em;
    border: none;
    background: none;
    color: white;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
}

.color-inherit {
    color: inherit !important;
}

.button-cp:focus {
    outline: none;
}

.button--border-thick {
    border: 3px solid;
}

.button--size-s {
    font-size: 14px;
}

/* Wayra */
.button--wayra {
    overflow: hidden;
    width: 245px;
    -webkit-transition: border-color 0.3s, color 0.3s;
    transition: border-color 0.3s, color 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--wayra::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background: var(--informa-green);
    z-index: -1;
    -webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.button--wayra:hover {
    color: #fff;
    border-color: #37474f;
}

.button--wayra.button--inverted:hover {
    color: #37474f !important;
    border-color: #fff;
}

.mask-cls {
    background-color: #6d9afc;
}

.button--wayra:hover::before {
    opacity: 1;
    background-color: #37474f;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--wayra.button--inverted:hover::before {
    background-color: #fff;
}

.welcome-bg-cls {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.toast-position {
    top: 5rem !important;
    right: 2rem !important;
}

/* LOADER START */
.loader .spinner {
    width: 44px;
    height: 44px;
    animation: loader-spinner 2s infinite ease;
    transform-style: preserve-3d;
}

.loader .spinner > div {
    /* background-color: rgba(var(--informa-green), .7); */
    /* color in scss file */
    height: 100%;
    position: absolute;
    width: 100%;
    border: 2px solid #155332;
}

.loader .spinner div:nth-of-type(1) {
    transform: translateZ(-22px) rotateY(180deg);
}

.loader .spinner div:nth-of-type(2) {
    transform: rotateY(-270deg) translateX(50%);
    transform-origin: top right;
}

.loader .spinner div:nth-of-type(3) {
    transform: rotateY(270deg) translateX(-50%);
    transform-origin: center left;
}

.loader .spinner div:nth-of-type(4) {
    transform: rotateX(90deg) translateY(-50%);
    transform-origin: top center;
}

.loader .spinner div:nth-of-type(5) {
    transform: rotateX(-90deg) translateY(50%);
    transform-origin: bottom center;
}

.loader .spinner div:nth-of-type(6) {
    transform: translateZ(22px);
}

@keyframes loader-spinner {
    0% {
        transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    }

    50% {
        transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    }

    100% {
        transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    }
}

/* LOADER END */

.tab-container .MuiTabs-root .MuiTabs-flexContainer {
    gap: 1rem;
}

.tab-container .MuiTabs-root .MuiButtonBase-root {
    border: 2px solid black;
    border-radius: 20px;
    padding: 5px 10px;
    min-height: 40px;
    font-weight: 600;
    color: black;
    width: 100px;
}

.tab-container .MuiTabs-root .MuiButtonBase-root:hover {
    opacity: 0.7;
    background-color: #eee;
}

.tab-container .MuiTabs-root .MuiButtonBase-root.Mui-selected {
    opacity: 1;
    background-color: var(--informa-green);
    border-color: var(--informa-green);
}

.tab-container .MuiTabs-root .MuiTabs-indicator {
    height: 0px;
}

.hover-progress {
    transition: 0.1s ease all;
}

.hover-progress:hover {
    background-color: rgba(51, 210, 126, 0.14);
}

.aspect-ratio-1 {
    aspect-ratio: 1 !important;
}

.background-white {
    background-color: white !important;
}

/* ALL MEDIA QUERY START */

@media screen and (max-width: 900px) {
    .side-by-side {
        flex-direction: column;
        margin: 0px 0 5px;
    }

    .custom-select {
        width: 100%;
    }

    .fullWidth-responsive {
        width: 100%;
    }

    .contact-page .side-by-side label {
        text-align: left;
    }

    .big-modal {
        min-width: 90vw;
        max-height: 85vh;
    }

    .medium-modal {
        min-width: 90vw;
        max-height: 85vh;
    }

    .doc_icon_size {
        height: 50px;
        width: 55px;
    }
}

@media screen and (max-width: 821px) {
    .reg-card-landing {
        min-height: auto;
    }

    .form-scroll {
        max-height: max(72vh, 360px);
    }
}

@media screen and (max-width: 768px) {
    :root {
        --logo-sz: 150px;
    }

    .button-cp {
        padding: 1rem 0.6rem;
    }

    .user-guide-content-h {
        max-height: 200px;
    }

    .min-h-add-btn {
        min-height: 182px;
    }

    .showcase_max_ht img {
        max-height: 200px;
    }

    .event_grid,
    .event_grid_4 {
        grid-template-columns: repeat(2, minmax(240px, 1fr));
    }

    .expand_tabs button {
        padding: 10px 20px;
        min-width: 150px;
        flex: 1 1 0px;
    }

    .hashtag-box,
    .hashtag-box-informa {
        font-size: 14px;
        margin: 5px 5px 3px 0;
        padding: 1px 10px;
    }

    .font-main-title-custom {
        font-size: 26px;
        font-weight: 600;
    }

    .font-title-custom {
        font-size: 20px;
    }

    .font-body-custom {
        font-size: 14px;
    }

    .display-name-value {
        font-size: 14px;
    }

    .display-name-value div:nth-child(1) {
        font-size: 0.7rem;
    }

    .display-name-value div:nth-child(2) {
        font-size: 1rem;
    }

    .container-see-more label {
        width: 94%;
        background-color: #acbbf0;
        text-align: center;
        margin: auto 10px auto;
        border-radius: 8px;
        right: auto;
        left: 0;
    }

    .container-see-more label:after {
        color: black;
    }

    .fixed-left {
        position: relative;
    }

    .reg-card-landing {
        max-width: 92vw;
    }

    .banner__img iframe {
        width: 100%;
        max-width: 100%;
        height: 300px;
    }

    .product__img {
        height: 220px;
    }

    .doc_icon_size {
        height: 50px;
        width: 40px;
    }

    .doc__box {
        min-height: 80px;
        flex-direction: column;
        justify-content: start;
    }

    .doc__box .title__box {
        text-align: start;
        font-size: 14px;
    }

    .modal_view__img {
        max-width: 70vw;
    }

    .showcase__title {
        margin-left: 0;
        margin-top: 70px;
        text-align: center;
    }

    .side-by-side label {
        font-size: 1rem;
    }
}

@media screen and (max-width: 464px) {
    :root {
        --logo-sz: 120px;
    }

    .showcase_max_ht img {
        max-height: 180px;
    }

    .single-product {
        max-width: 400px;
    }

    .product__img {
        max-width: 400px;
    }

    .event_grid,
    .event_grid_4 {
        grid-template-columns: repeat(1, minmax(160px, 1fr));
    }

    .contact-page {
        padding: 0 1rem;
    }

    .circle-icon {
        margin: 0.2rem 1rem;
    }

    .product-name-box {
        width: 70%;
    }
}

@media screen and (max-width: 375px) {
    .single-product {
        max-width: 360px;
    }

    .product__img {
        max-width: 360px;
    }
}
